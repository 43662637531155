import { Button } from '../../../pov-common-ui';
import * as React from 'react';
import './styles.scss';
import { copyTextToClipboard } from '../../../utilities/helpers';
import Tooltip from '../../tool-tip';
import { useTranslation } from 'react-i18next';

export interface IProps {
  closeModal: VoidFunction;
  anyConnect: IAnyConnectData;
}

export default function AnyConnect({ anyConnect, closeModal }: IProps) {
  const { t } = useTranslation();
  return (
    <div className="any-connect-modal">
      <div className="modal__header">
        <h1 className="modal__title">Session Details</h1>
      </div>

      <div className="modal__body">
        <div className="any-connect-content">
          {t('engagements.anyConnect.modal.content')}
        </div>

        <table className="any-connect-details">
          <tbody>
            <tr className="any-connect-row" id="host-container">
              <td className="any-connect-row__name">Host</td>
              <td className="any-connect-row__value">{anyConnect.host}</td>
              <td>
                <Tooltip content="Copy to clipboard">
                  <span
                    className="icon-recent-apps"
                    onClick={() => copyTextToClipboard(anyConnect.host)}
                  />
                </Tooltip>
              </td>
            </tr>

            <tr className="any-connect-row" id="user-container">
              <td className="any-connect-row__name">User</td>
              <td className="any-connect-row__value">{anyConnect.user}</td>
              <td>
                <Tooltip content="Copy to clipboard">
                  <span
                    className="icon-recent-apps"
                    onClick={() => copyTextToClipboard(anyConnect.user)}
                  />
                </Tooltip>
              </td>
            </tr>

            <tr className="any-connect-row" id="password-container">
              <td className="any-connect-row__name">Password</td>
              <td className="any-connect-row__value">{anyConnect.password}</td>
              <td>
                <Tooltip content="Copy to clipboard">
                  <span
                    className="icon-recent-apps"
                    onClick={() => copyTextToClipboard(anyConnect.password)}
                  />
                </Tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="modal__footer">
        <Button onClick={closeModal} id="any-connect-modal_ok" color="primary">
          OK
        </Button>
      </div>
    </div>
  );
}
